import React, {useState} from 'react'
import Item from './IssueItem'
import { Link } from "react-router-dom";

export default function Archieves(props) {
    const [Content, setContent] = useState(props.items1);
    const [headerIndex, setheaderIndex] = useState(0);


  return (
      
    <div>
        <div className="section inner_page_banner" style={{backgroundColor:"#ff8436"}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner_title">
                        <h3>Archives</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <div className="section layout_padding">
            <div className="container">
                <div className="row float-right w-100">
                    <div className="col-md-4" style={{"border":"1px solid"}}>
                    <div className="row">
            <div className="col-12 " style={{ textAlign: 'center', marginBottom: '10px', marginTop:'10px' }}>
              {/* <img src={"/sponser3.png"} alt="QTanalyics Logo" style={{ maxHeight: '50px', marginRight: '10px' }} /> */}
              <img src={"/Crossref logo.png"} alt="Crossref Logo" style={{ maxHeight: '50px' }} />
            </div>
          </div>
                        <div className="well volumes">
                            <h4>Volumes  Issues</h4>
                            <ul>
                                
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items1);setheaderIndex(0)}}><li name="issue1" >Vol 1, Issue 1, Jun 2021 </li></div> 
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items2);setheaderIndex(1)}}><li name="issue2" >Vol 1, Issue 2, Sep 2021</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items3);setheaderIndex(2)}}><li name="issue2" >Vol 1, Issue 3, Dec 2021</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items4);setheaderIndex(3)}}><li name="issue2" >Vol 2, Issue 1, Mar 2022</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items5);setheaderIndex(4)}}><li name="issue2" >Vol 2, Issue 2, Jun 2022</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items6);setheaderIndex(5)}}><li name="issue2" >Vol 2, Issue 3, Sep 2022</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items7);setheaderIndex(6)}}><li name="issue2" >Vol 2, Issue 4, Dec 2022</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items8);setheaderIndex(7)}}><li name="issue2" >Vol 3, Issue 1, Mar 2023</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items9);setheaderIndex(8)}}><li name="issue2" >Vol 3, Issue 2, Jun 2023</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items10);setheaderIndex(9)}}><li name="issue2" >Vol 3, Issue 3, Sep 2023</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items11);setheaderIndex(10)}}><li name="issue2" >Vol 3, Issue 4, Dec 2023</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items12);setheaderIndex(11)}}><li name="issue2" >Vol 4, Issue 1, Mar 2024</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items13);setheaderIndex(12)}}><li name="issue2" >Vol 4, Issue 2, Jun 2024</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items14);setheaderIndex(13)}}><li name="issue2" >Vol 4, Issue 3, Sep 2024</li></div>
                                <div style={{"cursor":"pointer"}} onClick={()=>{setContent(props.items14);setheaderIndex(14)}}><li name="issue2" >Vol 4, Issue 4, Dec 2024</li></div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-8">

                    <div className="heading_main text_align_center">
                            <h2 style={{"font-size":"225%"}} ><span className="theme_color"></span>{props.header1[headerIndex].title}
                            </h2>
                        </div>

                        <div className="col-lg-12"  style={{"border":"1px solid"}}>
                            <h4>Cover Page</h4>

                            <p className="hide descript"></p>
                            <div className="row icons-all">
                                <div className="col-md-4 col-4">
                                </div>
                                <div className="col-md-4 col-4">
                                </div>
                                <div className="col-md-4 col-4">
                                    <Link to={props.header1[headerIndex].coverPage}target="_blank">View Cover Page</Link>
                                </div>
                            </div>
                        </div>

                <div className="col-lg-12"  style={{"border":"1px solid"}}>
                    <h4>About the Journal</h4>

                    <p className="hide descript"></p>
                    <p></p>

                    <div className="row icons-all">
                        <div className="col-md-4 col-4">
                        </div>
                        <div className="col-md-4 col-4">
                        </div>
                        <div className="col-md-4 col-4">
                            <Link to="/img/Journal about us for cover.pdf" target="_blank">View About theJournal</Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12"  style={{"border":"1px solid"}}>
                    <div className="well">

                        <h4>Table of Contents</h4>

                        <p className="hide descript"></p>
                        <p></p>

                        <div className="row icons-all">
                            <div className="col-md-4 col-6">
                            </div>
                            <div className="col-md-4 col-6">
                            </div>

                            <div className="col-md-4 col-6">
                                <Link to={props.header1[headerIndex].tableOfContents} target="_blank">View Table of Contents</Link>
                            </div>
                        </div>
                    </div>
                </div>


                        {Content.map((item)=>{
                            return (

                                <Item items={item}/>
                            )
                        })}  
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
